@font-face {
  font-family: "Mangueira";
  font-weight: 400;
  src: url("./assets/fonts/mangueira/Mangueira-Regular.ttf") format("truetype"),
    url("./assets/fonts/mangueira/Mangueira-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "Mangueira";
  font-weight: 500;
  src: url("./assets/fonts/mangueira/Mangueira-Medium.ttf") format("truetype"),
    url("./assets/fonts/mangueira/Mangueira-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Mangueira";
  font-weight: 600;
  src: url("./assets/fonts/mangueira/Mangueira-SemiBold.ttf") format("truetype"),
    url("./assets/fonts/mangueira/Mangueira-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "Mangueira";
  font-weight: 700;
  src: url("./assets/fonts/mangueira/Mangueira-Bold.otf") format("opentype"),
    url("./assets/fonts/mangueira/Mangueira-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Mangueira";
  font-weight: 900;
  src: url("./assets/fonts/mangueira/Mangueira-Black.otf") format("opentype"),
    url("./assets/fonts/mangueira/Mangueira-Black.ttf") format("truetype");
}

:root {
  --white: #ffffff;
  --black: #1c1c1c;
  --gray600: #706f6f;
  --gray500: #9d9d9c;
  --gray500Opacity: rgba(218, 218, 218, 0.5);
  --gray400: #dadada;
  --gray300: #cecece;
  --gray200: #eeeeee;
  --purple600: #3a2e83;
  --purple500: #44389e;
  --purple400: #3a2e83;
  --purple300: #4b48b7;
  --pink600: #e50076;
  --pink500: #f74d92;
  --pink400: #f489ba;
  --red600: #bb2424;
  --red500: #bf6060;
  --orange600: #ec6726;
  --orange500: #f39655;
  --orange400: #ec6726;
  --orange200: #f7ac6f;
  --yellow600: #f9b104;
  --yellow500: #f4c871;
  --yellow400: #f9ce8f;
  --green600: #5db77a;
  --green500: #80cea0;
  --green400: #a4d8ba;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Mangueira", "Poppins", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  background-color: #f6f6f6;
  color: var(--black);
}

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
}

/*css para ajustar o tamanho da clara*/

body .open_updated_img {
  width: 100px;
}

body .drz_main_updated #drz_dialog_box_desktop::before {
  padding: 12px 10px;
}

@media (max-width: 800px) {
  body .open_updated_img {
    width: 70px;
  }

  body #drz_dialog_box_mobile {
    font-size: 12px;
  }
}

@media (max-width: 500px) {
  body .open_updated_img {
    width: 50px;
  }

  body #drz_dialog_box_mobile {
    font-size: 12px;
  }
}

#droz-div {
  z-index: 9 !important;
  position: fixed !important;
}